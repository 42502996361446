<template>
  <head>
    <title>Page not found - My CSC</title>
  </head>

  <h2 class="text-8xl text-center">
    {{ error?.statusCode }}
  </h2>

  <p class="text-center">Oh no! The page you are looking for does not exist.</p>

  <c-button @click="handleError">Back to safety</c-button>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app';

defineProps<{
  error?: NuxtError;
}>();

const handleError = () => clearError({ redirect: '/' });
</script>
